import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';

export default createComponent({
  name: 'CidGrupoPage',
  setup(props, ctx) {
    // const capituloId = useRoute().params.capituloId;

    // const grupoId = useRoute().params.grupoId;

    const $title = computed(() => 'grupo nome');

    return () => <MyPage title={$title.value} />;
  },
});
